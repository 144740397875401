.box:not(:last-child),
.content:not(:last-child),
.title:not(:last-child),
.subtitle:not(:last-child),
.level:not(:last-child),
.message:not(:last-child),
.tabs:not(:last-child) {
	margin-bottom: 1.5rem
}

.button,
.input,
.textarea,
.select select {
	-moz-appearance: none;
	-webkit-appearance: none;
	align-items: center;
	border: 1px solid transparent;
	border-radius: 4px;
	box-shadow: none;
	display: inline-flex;
	font-size: 1rem;
	height: 2.5em;
	justify-content: flex-start;
	line-height: 1.5;
	position: relative;
	vertical-align: top;
	padding: calc(0.5em - 1px) calc(0.75em - 1px) calc(0.5em - 1px) calc(0.75em - 1px)
}

.button:focus,
.input:focus,
.textarea:focus,
.select select:focus {
	outline: none
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: 400
}

ul {
	list-style: none
}

button,
input,
select,
textarea {
	margin: 0
}

html {
	box-sizing: border-box
}

*,
::before,
::after {
	box-sizing: inherit
}

img,
video {
	height: auto;
	max-width: 100%
}

iframe {
	border: 0
}

html {
	background-color: #fff;
	font-size: 16px;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	min-width: 300px;
	overflow-x: hidden;
	overflow-y: scroll;
	text-rendering: optimizeLegibility;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
	display: block
}

a {
	color: #3273dc;
	cursor: pointer;
	text-decoration: none
}

a strong {
	color: currentColor
}

a:hover {
	color: #363636
}

hr {
	background-color: #f5f5f5;
	border: none;
	display: block;
	height: 2px;
	margin: 1.5rem 0
}

img {
	height: auto;
	max-width: 100%
}

input[type="checkbox"],
input[type="radio"] {
	vertical-align: baseline
}

small {
	font-size: .875em
}

span {
	font-style: inherit;
	font-weight: inherit
}

strong {
	color: #363636;
	font-weight: 700
}

fieldset {
	border: none
}

.button {
	background-color: #fff;
	border-color: #dbdbdb;
	border-width: 1px;
	color: #363636;
	cursor: pointer;
	justify-content: center;
	text-align: center;
	white-space: nowrap;
	padding: calc(0.5em - 1px) 1em calc(0.5em - 1px) 1em
}

.button strong {
	color: inherit
}

.button .icon,
.button .icon.is-small,
.button .icon.is-medium,
.button .icon.is-large {
	height: 1.5em;
	width: 1.5em
}

.button .icon:first-child:not(:last-child) {
	margin-left: calc(-0.5em - 1px);
	margin-right: .25em
}

.button .icon:last-child:not(:first-child) {
	margin-left: .25em;
	margin-right: calc(-0.5em - 1px)
}

.button .icon:first-child:last-child {
	margin-left: calc(-0.5em - 1px);
	margin-right: calc(-0.5em - 1px)
}

.button.is-primary {
	background-color: #00d1b2;
	border-color: transparent;
	color: #fff
}
.button.is-link {
	background-color: #485fc7;
	border-color: transparent;
	color: #fff
}


.button.is-info {
	background-color: #3298dc;
	border-color: transparent;
	color: #fff
}

.button.is-success {
	background-color: #48c774;
	border-color: transparent;
	color: #fff
}

.button.is-warning {
	background-color: #ffdd57;
	border-color: transparent;
	color: rgba(0, 0, 0, 0.7)
}

.button.is-danger {
	background-color: #cd3150;
	border-color: transparent;
	color: #fff
}

.button.is-small {
	border-radius: 2px;
	font-size: .75rem
}

.button.is-normal {
	font-size: 1rem
}

.button.is-medium {
	font-size: 1.25rem
}

.button.is-large {
	font-size: 1.5rem
}

.button.is-fullwidth {
	display: flex;
	width: 100%
}

.button.is-rounded {
	border-radius: 290486px;
	padding-left: calc(1em + 0.25em);
	padding-right: calc(1em + 0.25em)
}

.buttons {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start
}

.container {
	flex-grow: 1;
	margin: 0 auto;
	position: relative;
	width: auto
}

.container.is-fluid {
	max-width: none!important;
	padding-left: 32px;
	padding-right: 32px;
	width: 100%
}

@media screen and (min-width: 1024px) {
	.container {
		max-width: 960px
	}
}

@media screen and (min-width: 1216px) {
	.container:not(.is-max-desktop) {
		max-width: 1152px
	}
}

@media screen and (min-width: 1408px) {
	.container:not(.is-max-desktop):not(.is-max-widescreen) {
		max-width: 1344px
	}
}



.content p:not(:last-child),
.content dl:not(:last-child),
.content ol:not(:last-child),
.content ul:not(:last-child),
.content blockquote:not(:last-child),
.content pre:not(:last-child),
.content table:not(:last-child) {
	margin-bottom: 1em
}



.content figure {
	margin-left: 2em;
	margin-right: 2em;
	text-align: center
}

.image {
	display: block;
	position: relative
}

.image img {
	display: block;
	height: auto;
	width: 100%
}

.image img.is-rounded {
	border-radius: 290486px
}

.image.is-fullwidth {
	width: 100%
}

.image.is-16x16 {
	height: 16px;
	width: 16px
}

.image.is-24x24 {
	height: 24px;
	width: 24px
}

.image.is-32x32 {
	height: 32px;
	width: 32px
}

.image.is-48x48 {
	height: 48px;
	width: 48px
}

.image.is-64x64 {
	height: 64px;
	width: 64px
}

.image.is-128x128 {
	height: 128px;
	width: 128px
}


.tags {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start
}

.tags .tag {
	margin-bottom: .5rem
}

.tags .tag:not(:last-child) {
	margin-right: .5rem
}

.tags:last-child {
	margin-bottom: -.5rem
}

.tags:not(:last-child) {
	margin-bottom: 1rem
}

.tags.are-medium .tag:not(.is-normal):not(.is-large) {
	font-size: 1rem
}

.tags.are-large .tag:not(.is-normal):not(.is-medium) {
	font-size: 1.25rem
}

.tags.is-centered {
	justify-content: center
}

.tags.is-centered .tag {
	margin-right: .25rem;
	margin-left: .25rem
}

.tags.is-right {
	justify-content: flex-end
}

.tags.is-right .tag:not(:first-child) {
	margin-left: .5rem
}

.tags.is-right .tag:not(:last-child) {
	margin-right: 0
}

.tags.has-addons .tag {
	margin-right: 0
}

.tags.has-addons .tag:not(:first-child) {
	margin-left: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.tags.has-addons .tag:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.tag:not(body) {
	align-items: center;
	background-color: #f5f5f5;
	border-radius: 4px;
	color: #4a4a4a;
	display: inline-flex;
	font-size: .75rem;
	height: 2em;
	justify-content: center;
	line-height: 1.5;
	padding-left: .75em;
	padding-right: .75em;
	white-space: nowrap
}

.tag:not(body) .delete {
	margin-left: .25rem;
	margin-right: -.375rem
}

.tag:not(body).is-white {
	background-color: #fff;
	color: #0a0a0a
}

.tag:not(body).is-black {
	background-color: #0a0a0a;
	color: #fff
}

.tag:not(body).is-light {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.7)
}

.tag:not(body).is-dark {
	background-color: #363636;
	color: #fff
}


.button.is-dark {
	background-color: #363636;
	border-color: transparent;
	color: #fff
}

.tag:not(body).is-primary {
	background-color: #00d1b2;
	color: #fff
}

.tag:not(body).is-primary.is-light {
	background-color: #ebfffc;
	color: #00947e
}

.tag:not(body).is-link {
	background-color: #3273dc;
	color: #fff
}

.tag:not(body).is-link.is-light {
	background-color: #eef3fc;
	color: #2160c4
}

.tag:not(body).is-info {
	background-color: #3298dc;
	color: #fff
}

.tag:not(body).is-info.is-light {
	background-color: #eef6fc;
	color: #1d72aa
}

.tag:not(body).is-success {
	background-color: #48c774;
	color: #fff
}

.tag:not(body).is-success.is-light {
	background-color: #effaf3;
	color: #257942
}

.tag:not(body).is-warning {
	background-color: #ffdd57;
	color: rgba(0, 0, 0, 0.7)
}

.tag:not(body).is-warning.is-light {
	background-color: #fffbeb;
	color: #947600
}

.tag:not(body).is-danger {
	background-color: #f14668;
	color: #fff
}

.tag:not(body).is-danger.is-light {
	background-color: #feecf0;
	color: #cc0f35
}

.tag:not(body).is-normal {
	font-size: .75rem
}

.tag:not(body).is-medium {
	font-size: 1rem
}

.tag:not(body).is-large {
	font-size: 1.25rem
}

.tag:not(body) .icon:first-child:not(:last-child) {
	margin-left: -.375em;
	margin-right: .1875em
}

.tag:not(body) .icon:last-child:not(:first-child) {
	margin-left: .1875em;
	margin-right: -.375em
}

.tag:not(body) .icon:first-child:last-child {
	margin-left: -.375em;
	margin-right: -.375em
}

.tag:not(body).is-delete {
	margin-left: 1px;
	padding: 0;
	position: relative;
	width: 2em
}

.tag:not(body).is-delete::before,
.tag:not(body).is-delete::after {
	background-color: currentColor;
	content: "";
	display: block;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
	transform-origin: center center
}

.tag:not(body).is-delete::before {
	height: 1px;
	width: 50%
}

.tag:not(body).is-delete::after {
	height: 50%;
	width: 1px
}

.tag:not(body).is-delete:hover,
.tag:not(body).is-delete:focus {
	background-color: #e8e8e8
}

.tag:not(body).is-delete:active {
	background-color: #dbdbdb
}

.tag:not(body).is-rounded {
	border-radius: 290486px
}

a.tag:hover {
	text-decoration: underline
}

.title,
.subtitle {
	word-break: break-word
}

.title em,
.title span,
.subtitle em,
.subtitle span {
	font-weight: inherit
}

.title sub,
.subtitle sub {
	font-size: .75em
}

.title sup,
.subtitle sup {
	font-size: .75em
}

.title .tag,
.subtitle .tag {
	vertical-align: middle
}

.title {
	color: #363636;
	font-size: 2rem;
	font-weight: 600;
    line-height: 1.125
    
}

.title strong {
	color: inherit;
	font-weight: inherit
}

.title+.highlight {
	margin-top: -.75rem
}

.title:not(.is-spaced)+.subtitle {
	margin-top: -1.25rem
}

.title.is-1 {
	font-size: 3rem
}

.title.is-2 {
	font-size: 2.5rem
}

.title.is-3 {
	font-size: 2rem
}

.title.is-4 {
	font-size: 1.5rem
}

.title.is-5 {
	font-size: 1.25rem
}

.title.is-6 {
	font-size: 1rem
}

.title.is-7 {
	font-size: .75rem
}

.subtitle {
	color: #4a4a4a;
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.25
}

.subtitle strong {
	color: #363636;
	font-weight: 600
}

.subtitle:not(.is-spaced)+.title {
	margin-top: -1.25rem
}

.subtitle.is-1 {
	font-size: 3rem
}

.subtitle.is-2 {
	font-size: 2.5rem
}

.subtitle.is-3 {
	font-size: 2rem
}

.subtitle.is-4 {
	font-size: 1.5rem
}

.subtitle.is-5 {
	font-size: 1.25rem
}

.subtitle.is-6 {
	font-size: 1rem
}

.subtitle.is-7 {
	font-size: .75rem
}

.heading {
	display: block;
	font-size: 11px;
	letter-spacing: 1px;
	margin-bottom: 5px;
	text-transform: uppercase
}

.input,
.textarea,
.select select {
	background-color: #fff;
	border-color: #dbdbdb;
	border-radius: 4px;
	color: #363636
}

.icon {
	align-items: center;
	display: inline-flex;
	justify-content: center;
	height: 1.5rem;
	width: 1.5rem
}

.icon.is-small {
	height: 1rem;
	width: 1rem
}

.icon.is-medium {
	height: 2rem;
	width: 2rem
}

.icon.is-large {
	height: 3rem;
	width: 3rem
}

.input::-moz-placeholder,
.textarea::-moz-placeholder,
.select select::-moz-placeholder {
	color: rgba(54, 54, 54, 0.3)
}

.input::-webkit-input-placeholder,
.textarea::-webkit-input-placeholder,
.select select::-webkit-input-placeholder {
	color: rgba(54, 54, 54, 0.3)
}

.input:-moz-placeholder,
.textarea:-moz-placeholder,
.select select:-moz-placeholder {
	color: rgba(54, 54, 54, 0.3)
}

.input:-ms-input-placeholder,
.textarea:-ms-input-placeholder,
.select select:-ms-input-placeholder {
	color: rgba(54, 54, 54, 0.3)
}

.input:hover,
.textarea:hover,
.select select:hover,
.is-hovered.input,
.is-hovered.textarea,
.select select.is-hovered {
	border-color: #b5b5b5
}

.input:focus,
.textarea:focus,
.select select:focus,
.is-focused.input,
.is-focused.textarea,
.select select.is-focused,
.input:active,
.textarea:active,
.select select:active,
.is-active.input,
.is-active.textarea,
.select select.is-active {
	border-color: #3273dc;
	box-shadow: 0 0 0 .125em rgba(50, 115, 220, 0.25)
}

.input,
.textarea {
	box-shadow: inset 0 .0625em .125em rgba(10, 10, 10, 0.05);
	max-width: 100%;
	width: 100%
}

.input[readonly],
.textarea[readonly] {
	box-shadow: none
}

.is-small.input,
.is-small.textarea {
	border-radius: 2px;
	font-size: .75rem
}

.is-medium.input,
.is-medium.textarea {
	font-size: 1.25rem
}

.is-large.input,
.is-large.textarea {
	font-size: 1.5rem
}

.is-fullwidth.input,
.is-fullwidth.textarea {
	display: block;
	width: 100%
}

.is-inline.input,
.is-inline.textarea {
	display: inline;
	width: auto
}

.input.is-rounded {
	border-radius: 290486px;
	padding-left: calc(calc(0.75em - 1px) + 0.375em);
	padding-right: calc(calc(0.75em - 1px) + 0.375em)
}

.textarea {
	display: block;
	max-width: 100%;
	min-width: 100%;
	padding: calc(0.75em - 1px);
	resize: vertical
}

.textarea:not([rows]) {
	max-height: 40em;
	min-height: 8em
}

.textarea[rows] {
	height: initial
}

.textarea.has-fixed-size {
	resize: none
}

.checkbox,
.radio {
	cursor: pointer;
	display: inline-block;
	line-height: 1.25;
	position: relative
}

.checkbox input,
.radio input {
	cursor: pointer
}

.checkbox:hover,
.radio:hover {
	color: #363636
}

.checkbox[disabled],
.radio[disabled],
fieldset[disabled] .checkbox,
fieldset[disabled] .radio,
.checkbox input[disabled],
.radio input[disabled] {
	color: #7a7a7a;
	cursor: not-allowed
}

.radio+.radio {
	margin-left: .5em
}

.select {
	display: inline-block;
	max-width: 100%;
	position: relative;
	vertical-align: top
}

.select:not(.is-multiple) {
	height: 2.5em
}

.select:not(.is-multiple):not(.is-loading)::after {
	border-color: #3273dc;
	right: 1.125em;
	z-index: 4
}

.select.is-rounded select {
	border-radius: 290486px;
	padding-left: 1em
}

.select select {
	cursor: pointer;
	display: block;
	font-size: 1em;
	max-width: 100%;
	outline: none
}

.select select::-ms-expand {
	display: none
}

.select select[disabled]:hover,
fieldset[disabled] .select select:hover {
	border-color: #f5f5f5
}

.select select:not([multiple]) {
	padding-right: 2.5em
}

.select select[multiple] {
	height: auto;
	padding: 0
}

.select select[multiple] option {
	padding: .5em 1em
}

.select:not(.is-multiple):not(.is-loading):hover::after {
	border-color: #363636
}

.select.is-small {
	border-radius: 2px;
	font-size: .75rem
}

.select.is-medium {
	font-size: 1.25rem
}

.select.is-large {
	font-size: 1.5rem
}

.select.is-fullwidth {
	width: 100%
}

.select.is-fullwidth select {
	width: 100%
}

.label {
	color: #363636;
	display: block;
	font-size: 1rem;
	font-weight: 700
}

.field:not(:last-child) {
	margin-bottom: .75rem
}

.field.has-addons {
	display: flex;
	justify-content: flex-start
}

.field.has-addons .control:not(:last-child) {
	margin-right: -1px
}

.field.has-addons .control:not(:first-child):not(:last-child) .button,
.field.has-addons .control:not(:first-child):not(:last-child) .input,
.field.has-addons .control:not(:first-child):not(:last-child) .select select {
	border-radius: 0
}

.field.has-addons .control:first-child:not(:only-child) .button,
.field.has-addons .control:first-child:not(:only-child) .input,
.field.has-addons .control:first-child:not(:only-child) .select select {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0
}

.field.has-addons .control:last-child:not(:only-child) .button,
.field.has-addons .control:last-child:not(:only-child) .input,
.field.has-addons .control:last-child:not(:only-child) .select select {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0
}

.control {
	box-sizing: border-box;
	clear: both;
	font-size: 1rem;
	position: relative;
	text-align: inherit
}

.control.has-icons-left .input:focus~.icon,
.control.has-icons-left .select:focus~.icon,
.control.has-icons-right .input:focus~.icon,
.control.has-icons-right .select:focus~.icon {
	color: #4a4a4a
}

.control.has-icons-left .input.is-small~.icon,
.control.has-icons-left .select.is-small~.icon,
.control.has-icons-right .input.is-small~.icon,
.control.has-icons-right .select.is-small~.icon {
	font-size: .75rem
}

.control.has-icons-left .input.is-medium~.icon,
.control.has-icons-left .select.is-medium~.icon,
.control.has-icons-right .input.is-medium~.icon,
.control.has-icons-right .select.is-medium~.icon {
	font-size: 1.25rem
}

.control.has-icons-left .input.is-large~.icon,
.control.has-icons-left .select.is-large~.icon,
.control.has-icons-right .input.is-large~.icon,
.control.has-icons-right .select.is-large~.icon {
	font-size: 1.5rem
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
	color: #dbdbdb;
	height: 2.5em;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 2.5em;
	z-index: 4
}

.control.has-icons-left .input,
.control.has-icons-left .select select {
	padding-left: 2.5em
}

.control.has-icons-left .icon.is-left {
	left: 0
}

.control.has-icons-right .input,
.control.has-icons-right .select select {
	padding-right: 2.5em
}

.control.has-icons-right .icon.is-right {
	right: 0
}

.card {
	background-color: #fff;
	border-radius: .25rem;
	box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
	color: #4a4a4a;
	max-width: 100%;
	overflow: hidden;
	position: relative
}

.card-content {
	background-color: transparent;
	padding: 1.5rem
}

.card-footer {
	background-color: transparent;
	border-top: 1px solid #ededed;
	align-items: stretch;
	display: flex
}

.dropdown {
	display: inline-flex;
	position: relative;
	vertical-align: top
}

.dropdown.is-active .dropdown-menu,
.dropdown.is-hoverable:hover .dropdown-menu {
	display: block
}

.dropdown.is-right .dropdown-menu {
	left: auto;
	right: 0
}

.dropdown.is-up .dropdown-menu {
	bottom: 100%;
	padding-bottom: 4px;
	padding-top: initial;
	top: auto
}

.dropdown-menu {
	display: none;
	left: 0;
	min-width: 12rem;
	padding-top: 4px;
	position: absolute;
	top: 100%;
	z-index: 20
}

.dropdown-content {
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
	padding-bottom: .5rem;
	padding-top: .5rem
}

.dropdown-item {
	color: #4a4a4a;
	display: block;
	font-size: .875rem;
	line-height: 1.5;
	padding: .375rem 1rem;
	position: relative
}

a.dropdown-item,
button.dropdown-item {
	padding-right: 3rem;
	text-align: inherit;
	white-space: nowrap;
	width: 100%
}

a.dropdown-item:hover,
button.dropdown-item:hover {
	background-color: #f5f5f5;
	color: #0a0a0a
}

a.dropdown-item.is-active,
button.dropdown-item.is-active {
	background-color: #3273dc;
	color: #fff
}

.dropdown-divider {
	background-color: #ededed;
	border: none;
	display: block;
	height: 1px;
	margin: .5rem 0
}

.level {
	align-items: center;
	justify-content: space-between
}

.level img {
	display: inline-block;
	vertical-align: top
}

.level.is-mobile {
	display: flex
}

.level.is-mobile .level-left,
.level.is-mobile .level-right {
	display: flex
}

.level.is-mobile .level-left+.level-right {
	margin-top: 0
}

.level.is-mobile .level-item:not(:last-child) {
	margin-bottom: 0;
	margin-right: .75rem
}

.level.is-mobile .level-item:not(.is-narrow) {
	flex-grow: 1
}

@media screen and (min-width: 1024px),
print {
	.level {
		display: flex
	}
	.level>.level-item:not(.is-narrow) {
		flex-grow: 1
	}
}

.level-item {
	align-items: center;
	display: flex;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	justify-content: center
}

.level-item .title,
.level-item .subtitle {
	margin-bottom: 0
}

@media screen and (max-width: 768px) {
	.level-item:not(:last-child) {
		margin-bottom: .75rem
	}
}

.level-left,
.level-right {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0
}

.level-left .level-item.is-flexible,
.level-right .level-item.is-flexible {
	flex-grow: 1
}

@media screen and (min-width: 1024px),
print {
	.level-left .level-item:not(:last-child),
	.level-right .level-item:not(:last-child) {
		margin-right: .75rem
	}
}

.level-left {
	align-items: center;
	justify-content: flex-start
}

@media screen and (max-width: 768px) {
	.level-left+.level-right {
		margin-top: 1.5rem
	}
}

@media screen and (min-width: 1024px),
print {
	.level-left {
		display: flex
	}
}

.level-right {
	align-items: center;
	justify-content: flex-end
}

@media screen and (min-width: 1024px),
print {
	.level-right {
		display: flex
	}
}

.media {
	align-items: flex-start;
	display: flex;
	text-align: inherit
}

.media .content:not(:last-child) {
	margin-bottom: .75rem
}

.media .media {
	border-top: 1px solid rgba(219, 219, 219, 0.5);
	display: flex;
	padding-top: .75rem
}

.media .media .content:not(:last-child),
.media .media .control:not(:last-child) {
	margin-bottom: .5rem
}

.media .media .media {
	padding-top: .5rem
}

.media .media .media+.media {
	margin-top: .5rem
}

.media+.media {
	border-top: 1px solid rgba(219, 219, 219, 0.5);
	margin-top: 1rem;
	padding-top: 1rem
}

.media.is-large+.media {
	margin-top: 1.5rem;
	padding-top: 1.5rem
}

.media-left,
.media-right {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0
}

.media-left {
	margin-right: 1rem
}

.media-right {
	margin-left: 1rem
}

.media-content {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	text-align: inherit
}

@media screen and (max-width: 768px) {
	.media-content {
		overflow-x: auto
	}
}

.navbar {
	background-color: #fff;
	min-height: 3.25rem;
    position: relative;
    color:#fff;
	z-index: 30
}

.navbar.is-dark {
	background-color: #363636;
}
.navbar.is-danger {
	background-color: #947600;
}
.navbar.is-warning {
	background-color: #ffdd57;
}


.navbar-brand>.navbar-item,
.navbar-brand .navbar-link {
	color: #fff
}

.navbar.is-primary {
	background-color: #00d1b2;
}

.navbar.is-info {
	background-color: #3298dc;
}


.navbar.is-success {
	background-color: #48c774;
}

.navbar>.container {
	align-items: stretch;
	display: flex;
	min-height: 3.25rem;
	width: 100%
}

.navbar.has-shadow {
	box-shadow: 0 2px 0 0 #f5f5f5
}

.navbar.is-fixed-bottom,
.navbar.is-fixed-top {
	left: 0;
	position: fixed;
	right: 0;
	z-index: 30
}

.navbar.is-fixed-bottom {
	bottom: 0
}

.navbar.is-fixed-bottom.has-shadow {
	box-shadow: 0 -2px 0 0 #f5f5f5
}

.navbar.is-fixed-top {
	top: 0
}

html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
	padding-top: 3.25rem
}

html.has-navbar-fixed-bottom,
body.has-navbar-fixed-bottom {
	padding-bottom: 3.25rem
}

.navbar-brand,
.navbar-tabs {
	align-items: stretch;
	display: flex;
	flex-shrink: 0;
	min-height: 3.25rem
}

.navbar-brand a.navbar-item:focus,
.navbar-brand a.navbar-item:hover {
	background-color: transparent
}

.navbar-item,
.navbar-link {
	color: #4a4a4a;
	display: block;
	line-height: 1.5;
	padding: .5rem .75rem;
	position: relative
}

.navbar-item .icon:only-child,
.navbar-link .icon:only-child {
	margin-left: -.25rem;
	margin-right: -.25rem
}

a.navbar-item,
.navbar-link {
	cursor: pointer
}

.navbar-item {
	flex-grow: 0;
	flex-shrink: 0
}

.navbar-item img {
	max-height: 1.75rem
}

.navbar-item.has-dropdown {
	padding: 0
}

.navbar-item.is-expanded {
	flex-grow: 1;
	flex-shrink: 1
}

.navbar-item.is-tab {
	border-bottom: 1px solid transparent;
	min-height: 3.25rem;
	padding-bottom: calc(0.5rem - 1px)
}

.navbar-item.is-tab:focus,
.navbar-item.is-tab:hover {
	background-color: transparent;
	border-bottom-color: #3273dc
}

.navbar-item.is-tab.is-active {
	background-color: transparent;
	border-bottom-color: #3273dc;
	border-bottom-style: solid;
	border-bottom-width: 3px;
	color: #3273dc;
	padding-bottom: calc(0.5rem - 3px)
}

.navbar-content {
	flex-grow: 1;
	flex-shrink: 1
}

.navbar-link:not(.is-arrowless) {
	padding-right: 2.5em
}

.navbar-link:not(.is-arrowless)::after {
	border-color: #3273dc;
	margin-top: -.375em;
	right: 1.125em
}

.navbar-dropdown {
	font-size: .875rem;
	padding-bottom: .5rem;
	padding-top: .5rem
}

.navbar-dropdown .navbar-item {
	padding-left: 1.5rem;
	padding-right: 1.5rem
}

.navbar-divider {
	background-color: #f5f5f5;
	border: none;
	display: none;
	height: 2px;
	margin: .5rem 0
}

@media screen and (max-width: 1023px) {
	.navbar>.container {
		display: block
	}
	.navbar-brand .navbar-item,
	.navbar-tabs .navbar-item {
		align-items: center;
		display: flex
	}
	.navbar-link::after {
		display: none
	}
}

@media screen and (min-width: 1024px) {
	.navbar,
	.navbar-start,
	.navbar-end {
		align-items: stretch;
		display: flex
	}
	.navbar {
		min-height: 3.25rem
	}
	.navbar.is-spaced {
		padding: 1rem 2rem
	}
	.navbar.is-spaced .navbar-start,
	.navbar.is-spaced .navbar-end {
		align-items: center
	}
	.navbar.is-spaced a.navbar-item,
	.navbar.is-spaced .navbar-link {
		border-radius: 4px
	}
	.navbar-burger {
		display: none
	}
	.navbar-item,
	.navbar-link {
		align-items: center;
		display: flex
	}
	.navbar-item.has-dropdown {
		align-items: stretch
	}
	.navbar-item.has-dropdown-up .navbar-link::after {
		transform: rotate(135deg) translate(0.25em, -0.25em)
	}
	.navbar-item.has-dropdown-up .navbar-dropdown {
		border-bottom: 2px solid #dbdbdb;
		border-radius: 6px 6px 0 0;
		border-top: none;
		bottom: 100%;
		box-shadow: 0 -8px 8px rgba(10, 10, 10, 0.1);
		top: auto
	}
	.navbar-item.is-active .navbar-dropdown,
	.navbar-item.is-hoverable:focus .navbar-dropdown,
	.navbar-item.is-hoverable:focus-within .navbar-dropdown,
	.navbar-item.is-hoverable:hover .navbar-dropdown {
		display: block
	}
	.navbar.is-spaced .navbar-item.is-active .navbar-dropdown,
	.navbar-item.is-active .navbar-dropdown.is-boxed,
	.navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown,
	.navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed,
	.navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown,
	.navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed,
	.navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown,
	.navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
		opacity: 1;
		pointer-events: auto;
		transform: translateY(0)
	}
	.navbar-start {
		justify-content: flex-start;
		margin-right: auto
	}
	.navbar-end {
		justify-content: flex-end;
		margin-left: auto
	}
	.navbar-dropdown {
		background-color: #fff;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		border-top: 2px solid #dbdbdb;
		box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
		display: none;
		font-size: .875rem;
		left: 0;
		min-width: 100%;
		position: absolute;
		top: 100%;
		z-index: 20
	}
	.navbar-dropdown .navbar-item {
		padding: .375rem 1rem;
		white-space: nowrap
	}
	.navbar-dropdown a.navbar-item {
		padding-right: 3rem
	}
	.navbar-dropdown a.navbar-item:focus,
	.navbar-dropdown a.navbar-item:hover {
		background-color: #f5f5f5;
		color: #0a0a0a
	}
	.navbar-dropdown a.navbar-item.is-active {
		background-color: #f5f5f5;
		color: #3273dc
	}
	.navbar.is-spaced .navbar-dropdown,
	.navbar-dropdown.is-boxed {
		border-radius: 6px;
		border-top: none;
		box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
		display: block;
		opacity: 0;
		pointer-events: none;
		top: calc(100% + (-4px));
		transform: translateY(-5px);
		transition-duration: 86ms;
		transition-property: opacity, transform
	}
	.navbar-dropdown.is-right {
		left: auto;
		right: 0
	}
	.navbar-divider {
		display: block
	}
	.navbar>.container .navbar-brand,
	.container>.navbar .navbar-brand {
		margin-left: -.75rem
	}
	.navbar.is-fixed-bottom-desktop,
	.navbar.is-fixed-top-desktop {
		left: 0;
		position: fixed;
		right: 0;
		z-index: 30
	}
	.navbar.is-fixed-bottom-desktop {
		bottom: 0
	}
	.navbar.is-fixed-bottom-desktop.has-shadow {
		box-shadow: 0 -2px 3px rgba(10, 10, 10, 0.1)
	}
	.navbar.is-fixed-top-desktop {
		top: 0
	}
	html.has-navbar-fixed-top-desktop,
	body.has-navbar-fixed-top-desktop {
		padding-top: 3.25rem
	}
	html.has-navbar-fixed-bottom-desktop,
	body.has-navbar-fixed-bottom-desktop {
		padding-bottom: 3.25rem
	}
	html.has-spaced-navbar-fixed-top,
	body.has-spaced-navbar-fixed-top {
		padding-top: 5.25rem
	}
	html.has-spaced-navbar-fixed-bottom,
	body.has-spaced-navbar-fixed-bottom {
		padding-bottom: 5.25rem
	}
	a.navbar-item.is-active,
	.navbar-link.is-active {
		color: #0a0a0a
	}
	a.navbar-item.is-active:not(:focus):not(:hover),
	.navbar-link.is-active:not(:focus):not(:hover) {
		background-color: transparent
	}
	.navbar-item.has-dropdown:focus .navbar-link,
	.navbar-item.has-dropdown:hover .navbar-link,
	.navbar-item.has-dropdown.is-active .navbar-link {
		background-color: #fafafa
	}
}

.hero.is-fullheight-with-navbar {
	min-height: calc(100vh - 3.25rem)
}

.tabs {
	-webkit-overflow-scrolling: touch;
	align-items: stretch;
	display: flex;
	font-size: 1rem;
	justify-content: space-between;
	overflow: hidden;
	overflow-x: auto;
	white-space: nowrap
}

.tabs a {
	align-items: center;
	border-bottom-color: #dbdbdb;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	color: #4a4a4a;
	display: flex;
	justify-content: center;
	margin-bottom: -1px;
	padding: .5em 1em;
	vertical-align: top
}

.tabs a:hover {
	border-bottom-color: #363636;
	color: #363636
}

.tabs li {
	display: block
}

.tabs li.is-active a {
	border-bottom-color: #3273dc;
	color: #3273dc
}

.tabs ul {
	align-items: center;
	border-bottom-color: #dbdbdb;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	justify-content: flex-start
}

.tabs ul.is-left {
	padding-right: .75em
}

.tabs ul.is-center {
	flex: none;
	justify-content: center;
	padding-left: .75em;
	padding-right: .75em
}

.tabs ul.is-right {
	justify-content: flex-end;
	padding-left: .75em
}

.tabs .icon:first-child {
	margin-right: .5em
}

.tabs .icon:last-child {
	margin-left: .5em
}

.tabs.is-centered ul {
	justify-content: center
}

.tabs.is-right ul {
	justify-content: flex-end
}

.tabs.is-boxed a {
	border: 1px solid transparent;
	border-radius: 4px 4px 0 0
}

.tabs.is-boxed a:hover {
	background-color: #f5f5f5;
	border-bottom-color: #dbdbdb
}

.tabs.is-boxed li.is-active a {
	background-color: #fff;
	border-color: #dbdbdb;
	border-bottom-color: transparent!important
}

.tabs.is-fullwidth li {
	flex-grow: 1;
	flex-shrink: 0
}

.tabs.is-toggle a {
	border-color: #dbdbdb;
	border-style: solid;
	border-width: 1px;
	margin-bottom: 0;
	position: relative
}

.tabs.is-toggle a:hover {
	background-color: #f5f5f5;
	border-color: #b5b5b5;
	z-index: 2
}

.tabs.is-toggle li+li {
	margin-left: -1px
}

.tabs.is-toggle li:first-child a {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px
}

.tabs.is-toggle li:last-child a {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px
}

.tabs.is-toggle li.is-active a {
	background-color: #3273dc;
	border-color: #3273dc;
	color: #fff;
	z-index: 1
}

.tabs.is-toggle ul {
	border-bottom: none
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
	border-bottom-left-radius: 290486px;
	border-top-left-radius: 290486px;
	padding-left: 1.25em
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
	border-bottom-right-radius: 290486px;
	border-top-right-radius: 290486px;
	padding-right: 1.25em
}

.tabs.is-small {
	font-size: .75rem
}

.tabs.is-medium {
	font-size: 1.25rem
}

.tabs.is-large {
	font-size: 1.5rem
}

.column {
	display: block;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	padding: .75rem
}

.columns.is-mobile>.column.is-narrow {
	flex: none
}

.columns.is-mobile>.column.is-full {
	flex: none;
	width: 100%
}

.columns.is-mobile>.column.is-three-quarters {
	flex: none;
	width: 75%
}

.columns.is-mobile>.column.is-two-thirds {
	flex: none;
	width: 66.6666%
}

.columns.is-mobile>.column.is-half {
	flex: none;
	width: 50%
}

.columns.is-mobile>.column.is-one-third {
	flex: none;
	width: 33.3333%
}

.columns.is-mobile>.column.is-one-quarter {
	flex: none;
	width: 25%
}

.columns.is-mobile>.column.is-one-fifth {
	flex: none;
	width: 20%
}

.columns.is-mobile>.column.is-two-fifths {
	flex: none;
	width: 40%
}

.columns.is-mobile>.column.is-three-fifths {
	flex: none;
	width: 60%
}

.columns.is-mobile>.column.is-four-fifths {
	flex: none;
	width: 80%
}

.columns.is-mobile>.column.is-offset-three-quarters {
	margin-left: 75%
}

.columns.is-mobile>.column.is-offset-two-thirds {
	margin-left: 66.6666%
}

.columns.is-mobile>.column.is-offset-half {
	margin-left: 50%
}

.columns.is-mobile>.column.is-offset-one-third {
	margin-left: 33.3333%
}

.columns.is-mobile>.column.is-offset-one-quarter {
	margin-left: 25%
}

.columns.is-mobile>.column.is-offset-one-fifth {
	margin-left: 20%
}

.columns.is-mobile>.column.is-offset-two-fifths {
	margin-left: 40%
}

.columns.is-mobile>.column.is-offset-three-fifths {
	margin-left: 60%
}

.columns.is-mobile>.column.is-offset-four-fifths {
	margin-left: 80%
}

.columns.is-mobile>.column.is-0 {
	flex: none;
	width: 0
}

.columns.is-mobile>.column.is-offset-0 {
	margin-left: 0
}

.columns.is-mobile>.column.is-1 {
	flex: none;
	width: 8.33333%
}

.columns.is-mobile>.column.is-offset-1 {
	margin-left: 8.33333%
}

.columns.is-mobile>.column.is-2 {
	flex: none;
	width: 16.66667%
}

.columns.is-mobile>.column.is-offset-2 {
	margin-left: 16.66667%
}

.columns.is-mobile>.column.is-3 {
	flex: none;
	width: 25%
}

.columns.is-mobile>.column.is-offset-3 {
	margin-left: 25%
}

.columns.is-mobile>.column.is-4 {
	flex: none;
	width: 33.33333%
}

.columns.is-mobile>.column.is-offset-4 {
	margin-left: 33.33333%
}

.columns.is-mobile>.column.is-5 {
	flex: none;
	width: 41.66667%
}

.columns.is-mobile>.column.is-offset-5 {
	margin-left: 41.66667%
}

.columns.is-mobile>.column.is-6 {
	flex: none;
	width: 50%
}

.columns.is-mobile>.column.is-offset-6 {
	margin-left: 50%
}

.columns.is-mobile>.column.is-7 {
	flex: none;
	width: 58.33333%
}

.columns.is-mobile>.column.is-offset-7 {
	margin-left: 58.33333%
}

.columns.is-mobile>.column.is-8 {
	flex: none;
	width: 66.66667%
}

.columns.is-mobile>.column.is-offset-8 {
	margin-left: 66.66667%
}

.columns.is-mobile>.column.is-9 {
	flex: none;
	width: 75%
}

.columns.is-mobile>.column.is-offset-9 {
	margin-left: 75%
}

.columns.is-mobile>.column.is-10 {
	flex: none;
	width: 83.33333%
}

.columns.is-mobile>.column.is-offset-10 {
	margin-left: 83.33333%
}

.columns.is-mobile>.column.is-11 {
	flex: none;
	width: 91.66667%
}

.columns.is-mobile>.column.is-offset-11 {
	margin-left: 91.66667%
}

.columns.is-mobile>.column.is-12 {
	flex: none;
	width: 100%
}

.columns.is-mobile>.column.is-offset-12 {
	margin-left: 100%
}

@media screen and (max-width: 1024px) {
	.column.is-narrow-mobile {
		flex: none
	}
	.column.is-full-mobile {
		flex: none;
		width: 100%
	}
	.column.is-three-quarters-mobile {
		flex: none;
		width: 75%
	}
	.column.is-two-thirds-mobile {
		flex: none;
		width: 66.6666%
	}
	.column.is-half-mobile {
		flex: none;
		width: 50%
	}
	.column.is-one-third-mobile {
		flex: none;
		width: 33.3333%
	}
	.column.is-one-quarter-mobile {
		flex: none;
		width: 25%
	}
	.column.is-one-fifth-mobile {
		flex: none;
		width: 20%
	}
	.column.is-two-fifths-mobile {
		flex: none;
		width: 40%
	}
	.column.is-three-fifths-mobile {
		flex: none;
		width: 60%
	}
	.column.is-four-fifths-mobile {
		flex: none;
		width: 80%
	}
	.column.is-offset-three-quarters-mobile {
		margin-left: 75%
	}
	.column.is-offset-two-thirds-mobile {
		margin-left: 66.6666%
	}
	.column.is-offset-half-mobile {
		margin-left: 50%
	}
	.column.is-offset-one-third-mobile {
		margin-left: 33.3333%
	}
	.column.is-offset-one-quarter-mobile {
		margin-left: 25%
	}
	.column.is-offset-one-fifth-mobile {
		margin-left: 20%
	}
	.column.is-offset-two-fifths-mobile {
		margin-left: 40%
	}
	.column.is-offset-three-fifths-mobile {
		margin-left: 60%
	}
	.column.is-offset-four-fifths-mobile {
		margin-left: 80%
	}
	.column.is-0-mobile {
		flex: none;
		width: 0
	}
	.column.is-offset-0-mobile {
		margin-left: 0
	}
	.column.is-1-mobile {
		flex: none;
		width: 8.33333%
	}
	.column.is-offset-1-mobile {
		margin-left: 8.33333%
	}
	.column.is-2-mobile {
		flex: none;
		width: 16.66667%
	}
	.column.is-offset-2-mobile {
		margin-left: 16.66667%
	}
	.column.is-3-mobile {
		flex: none;
		width: 25%
	}
	.column.is-offset-3-mobile {
		margin-left: 25%
	}
	.column.is-4-mobile {
		flex: none;
		width: 33.33333%
	}
	.column.is-offset-4-mobile {
		margin-left: 33.33333%
	}
	.column.is-5-mobile {
		flex: none;
		width: 41.66667%
	}
	.column.is-offset-5-mobile {
		margin-left: 41.66667%
	}
	.column.is-6-mobile {
		flex: none;
		width: 50%
	}
	.column.is-offset-6-mobile {
		margin-left: 50%
	}
	.column.is-7-mobile {
		flex: none;
		width: 58.33333%
	}
	.column.is-offset-7-mobile {
		margin-left: 58.33333%
	}
	.column.is-8-mobile {
		flex: none;
		width: 66.66667%
	}
	.column.is-offset-8-mobile {
		margin-left: 66.66667%
	}
	.column.is-9-mobile {
		flex: none;
		width: 75%
	}
	.column.is-offset-9-mobile {
		margin-left: 75%
	}
	.column.is-10-mobile {
		flex: none;
		width: 83.33333%
	}
	.column.is-offset-10-mobile {
		margin-left: 83.33333%
	}
	.column.is-11-mobile {
		flex: none;
		width: 91.66667%
	}
	.column.is-offset-11-mobile {
		margin-left: 91.66667%
	}
	.column.is-12-mobile {
		flex: none;
		width: 100%
	}
	.column.is-offset-12-mobile {
		margin-left: 100%
	}
}

@media screen and (min-width: 1024px),
print {
	.column.is-narrow {
		flex: none
	}
	.column.is-full {
		flex: none;
		width: 100%
	}
	.column.is-three-quarters{
		flex: none;
		width: 75%
	}
	.column.is-two-thirds{
		flex: none;
		width: 66.6666%
	}
	.column.is-half{
		flex: none;
		width: 50%
	}
	.column.is-one-third{
		flex: none;
		width: 33.3333%
	}
	.column.is-one-quarter{
		flex: none;
		width: 25%
	}
	.column.is-one-fifth {
		flex: none;
		width: 20%
	}
	.column.is-two-fifths{
		flex: none;
		width: 40%
	}
	.column.is-three-fifths {
		flex: none;
		width: 60%
	}
	.column.is-four-fifths {
		flex: none;
		width: 80%
	}
	.column.is-offset-three-quarters {
		margin-left: 75%
	}
	.column.is-offset-two-thirds{
		margin-left: 66.6666%
	}
	.column.is-offset-half {
		margin-left: 50%
	}
	.column.is-offset-one-third{
		margin-left: 33.3333%
	}
	.column.is-offset-one-quarte {
		margin-left: 25%
	}
	.column.is-offset-one-fifth {
		margin-left: 20%
	}
	.column.is-offset-two-fifths {
		margin-left: 40%
	}
	.column.is-offset-three-fifths{
		margin-left: 60%
	}
	.column.is-offset-four-fifths{
		margin-left: 80%
	}
	.column.is-0{
		flex: none;
		width: 0
	}
	.column.is-offset-0{
		margin-left: 0
	}
	.column.is-1{
		flex: none;
		width: 8.33333%
	}
	.column.is-offset-1{
		margin-left: 8.33333%
	}
	.column.is-2{
		flex: none;
		width: 16.66667%
	}
	.column.is-offset-2 {
		margin-left: 16.66667%
	}
	.column.is-3 {
		flex: none;
		width: 25%
	}
	.column.is-offset-3 {
		margin-left: 25%
	}
	.column.is-4 {
		flex: none;
		width: 33.33333%
	}
	.column.is-offset-4 {
		margin-left: 33.33333%
	}
	.column.is-5 {
		flex: none;
		width: 41.66667%
	}
	.column.is-offset-5 {
		margin-left: 41.66667%
	}
	.column.is-6 {
		flex: none;
		width: 50%
	}
	.column.is-offset-6{
		margin-left: 50%
	}
	.column.is-7 {
		flex: none;
		width: 58.33333%
	}
	.column.is-offset-7 {
		margin-left: 58.33333%
	}
	.column.is-8{
		flex: none;
		width: 66.66667%
	}
	.column.is-offset-8{
		margin-left: 66.66667%
	}
	.column.is-9{
		flex: none;
		width: 75%
	}
	.column.is-offset-9{
		margin-left: 75%
	}
	.column.is-10{
		flex: none;
		width: 83.33333%
	}
	.column.is-offset-10 {
		margin-left: 83.33333%
	}
	.column.is-11 {
		flex: none;
		width: 91.66667%
	}
	.column.is-offset-11{
		margin-left: 91.66667%
	}
	.column.is-12{
		flex: none;
		width: 100%
	}
	.column.is-offset-12{
		margin-left: 100%
	}
}



@media screen and (min-width: 1024px) {
	.column.is-narrow-desktop {
		flex: none
	}
	.column.is-full-desktop {
		flex: none;
		width: 100%
	}
	.column.is-three-quarters-desktop {
		flex: none;
		width: 75%
	}
	.column.is-two-thirds-desktop {
		flex: none;
		width: 66.6666%
	}
	.column.is-half-desktop {
		flex: none;
		width: 50%
	}
	.column.is-one-third-desktop {
		flex: none;
		width: 33.3333%
	}
	.column.is-one-quarter-desktop {
		flex: none;
		width: 25%
	}
	.column.is-one-fifth-desktop {
		flex: none;
		width: 20%
	}
	.column.is-two-fifths-desktop {
		flex: none;
		width: 40%
	}
	.column.is-three-fifths-desktop {
		flex: none;
		width: 60%
	}
	.column.is-four-fifths-desktop {
		flex: none;
		width: 80%
	}
	.column.is-offset-three-quarters-desktop {
		margin-left: 75%
	}
	.column.is-offset-two-thirds-desktop {
		margin-left: 66.6666%
	}
	.column.is-offset-half-desktop {
		margin-left: 50%
	}
	.column.is-offset-one-third-desktop {
		margin-left: 33.3333%
	}
	.column.is-offset-one-quarter-desktop {
		margin-left: 25%
	}
	.column.is-offset-one-fifth-desktop {
		margin-left: 20%
	}
	.column.is-offset-two-fifths-desktop {
		margin-left: 40%
	}
	.column.is-offset-three-fifths-desktop {
		margin-left: 60%
	}
	.column.is-offset-four-fifths-desktop {
		margin-left: 80%
	}
	.column.is-0-desktop {
		flex: none;
		width: 0
	}
	.column.is-offset-0-desktop {
		margin-left: 0
	}
	.column.is-1-desktop {
		flex: none;
		width: 8.33333%
	}
	.column.is-offset-1-desktop {
		margin-left: 8.33333%
	}
	.column.is-2-desktop {
		flex: none;
		width: 16.66667%
	}
	.column.is-offset-2-desktop {
		margin-left: 16.66667%
	}
	.column.is-3-desktop {
		flex: none;
		width: 25%
	}
	.column.is-offset-3-desktop {
		margin-left: 25%
	}
	.column.is-4-desktop {
		flex: none;
		width: 33.33333%
	}
	.column.is-offset-4-desktop {
		margin-left: 33.33333%
	}
	.column.is-5-desktop {
		flex: none;
		width: 41.66667%
	}
	.column.is-offset-5-desktop {
		margin-left: 41.66667%
	}
	.column.is-6-desktop {
		flex: none;
		width: 50%
	}
	.column.is-offset-6-desktop {
		margin-left: 50%
	}
	.column.is-7-desktop {
		flex: none;
		width: 58.33333%
	}
	.column.is-offset-7-desktop {
		margin-left: 58.33333%
	}
	.column.is-8-desktop {
		flex: none;
		width: 66.66667%
	}
	.column.is-offset-8-desktop {
		margin-left: 66.66667%
	}
	.column.is-9-desktop {
		flex: none;
		width: 75%
	}
	.column.is-offset-9-desktop {
		margin-left: 75%
	}
	.column.is-10-desktop {
		flex: none;
		width: 83.33333%
	}
	.column.is-offset-10-desktop {
		margin-left: 83.33333%
	}
	.column.is-11-desktop {
		flex: none;
		width: 91.66667%
	}
	.column.is-offset-11-desktop {
		margin-left: 91.66667%
	}
	.column.is-12-desktop {
		flex: none;
		width: 100%
	}
	.column.is-offset-12-desktop {
		margin-left: 100%
	}
}


.columns {
	margin-left: -.75rem;
	margin-right: -.75rem;
	margin-top: -.75rem
}

.columns:last-child {
	margin-bottom: -.75rem
}

.columns:not(:last-child) {
	margin-bottom: calc(1.5rem - 0.75rem)
}

.columns.is-centered {
	justify-content: center
}

.columns.is-gapless {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0
}

.columns.is-gapless>.column {
	margin: 0;
	padding: 0!important
}

.columns.is-gapless:not(:last-child) {
	margin-bottom: 1.5rem
}

.columns.is-gapless:last-child {
	margin-bottom: 0
}

.columns.is-mobile {
	display: flex
}

.columns.is-multiline {
	flex-wrap: wrap
}

.columns.is-vcentered {
	align-items: center
}

@media screen and (min-width: 1024px),
print {
	.columns:not(.is-desktop) {
		display: flex
	}
}

@media screen and (min-width: 1024px) {
	.columns.is-desktop {
		display: flex
	}
}

.columns.is-variable {
	--columngap: .75rem;
	margin-left: calc(-1 * var(--columnGap));
	margin-right: calc(-1 * var(--columnGap))
}

.columns.is-variable .column {
	padding-left: var(--columnGap);
	padding-right: var(--columnGap)
}

.columns.is-variable.is-0 {
	--columngap: 0
}

@media screen and (max-width: 768px) {
	.columns.is-variable.is-0-mobile {
		--columngap: 0
	}
}

@media screen and (min-width: 1024px),
print {
	.columns.is-variable.is-0-tablet {
		--columngap: 0
	}
}

@media screen and (min-width: 1024px) and (max-width: 1023px) {
	.columns.is-variable.is-0-tablet-only {
		--columngap: 0
	}
}



@media screen and (min-width: 1024px) {
	.columns.is-variable.is-0-desktop {
		--columngap: 0
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.columns.is-variable.is-0-desktop-only {
		--columngap: 0
	}
}


.columns.is-variable.is-1 {
	--columngap: .25rem
}

@media screen and (max-width: 768px) {
	.columns.is-variable.is-1-mobile {
		--columngap: .25rem
	}
}



@media screen and (min-width: 1024px) {
	.columns.is-variable.is-1-desktop {
		--columngap: .25rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.columns.is-variable.is-1-desktop-only {
		--columngap: .25rem
	}
}

.columns.is-variable.is-2 {
	--columngap: .5rem
}

@media screen and (max-width: 768px) {
	.columns.is-variable.is-2-mobile {
		--columngap: .5rem
	}
}

@media screen and (min-width: 1024px),
print {
	.columns.is-variable.is-2-tablet {
		--columngap: .5rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1023px) {
	.columns.is-variable.is-2-tablet-only {
		--columngap: .5rem
	}
}



@media screen and (min-width: 1024px) {
	.columns.is-variable.is-2-desktop {
		--columngap: .5rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.columns.is-variable.is-2-desktop-only {
		--columngap: .5rem
	}
}

.columns.is-variable.is-3 {
	--columngap: .75rem
}

@media screen and (max-width: 768px) {
	.columns.is-variable.is-3-mobile {
		--columngap: .75rem
	}
}

@media screen and (min-width: 1024px),
print {
	.columns.is-variable.is-3-tablet {
		--columngap: .75rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1023px) {
	.columns.is-variable.is-3-tablet-only {
		--columngap: .75rem
	}
}


@media screen and (min-width: 1024px) {
	.columns.is-variable.is-3-desktop {
		--columngap: .75rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.columns.is-variable.is-3-desktop-only {
		--columngap: .75rem
	}
}

.columns.is-variable.is-4 {
	--columngap: 1rem
}

@media screen and (max-width: 768px) {
	.columns.is-variable.is-4-mobile {
		--columngap: 1rem
	}
}

@media screen and (min-width: 1024px),
print {
	.columns.is-variable.is-4-tablet {
		--columngap: 1rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1023px) {
	.columns.is-variable.is-4-tablet-only {
		--columngap: 1rem
	}
}


@media screen and (min-width: 1024px) {
	.columns.is-variable.is-4-desktop {
		--columngap: 1rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.columns.is-variable.is-4-desktop-only {
		--columngap: 1rem
	}
}


.columns.is-variable.is-5 {
	--columngap: 1.25rem
}

@media screen and (max-width: 768px) {
	.columns.is-variable.is-5-mobile {
		--columngap: 1.25rem
	}
}


@media screen and (min-width: 1024px) {
	.columns.is-variable.is-5-desktop {
		--columngap: 1.25rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.columns.is-variable.is-5-desktop-only {
		--columngap: 1.25rem
	}
}

.columns.is-variable.is-6 {
	--columngap: 1.5rem
}

@media screen and (max-width: 768px) {
	.columns.is-variable.is-6-mobile {
		--columngap: 1.5rem
	}
}

@media screen and (min-width: 1024px),
print {
	.columns.is-variable.is-6-tablet {
		--columngap: 1.5rem
	}
}

@media screen and (min-width: 1024px) {
	.columns.is-variable.is-6-desktop {
		--columngap: 1.5rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.columns.is-variable.is-6-desktop-only {
		--columngap: 1.5rem
	}
}

.columns.is-variable.is-7 {
	--columngap: 1.75rem
}

@media screen and (max-width: 768px) {
	.columns.is-variable.is-7-mobile {
		--columngap: 1.75rem
	}
}

@media screen and (min-width: 1024px),
print {
	.columns.is-variable.is-7-tablet {
		--columngap: 1.75rem
	}
}

@media screen and (min-width: 1024px) {
	.columns.is-variable.is-7-desktop {
		--columngap: 1.75rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.columns.is-variable.is-7-desktop-only {
		--columngap: 1.75rem
	}
}

.columns.is-variable.is-8 {
	--columngap: 2rem
}

@media screen and (max-width: 768px) {
	.columns.is-variable.is-8-mobile {
		--columngap: 2rem
	}
}


@media screen and (min-width: 1024px) {
	.columns.is-variable.is-8-desktop {
		--columngap: 2rem
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.columns.is-variable.is-8-desktop-only {
		--columngap: 2rem
	}
}

.tile {
	align-items: stretch;
	display: block;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	min-height: -webkit-min-content;
	min-height: -moz-min-content;
	min-height: min-content
}

.tile.is-ancestor {
	margin-left: -.75rem;
	margin-right: -.75rem;
	margin-top: -.75rem
}

.tile.is-ancestor:last-child {
	margin-bottom: -.75rem
}

.tile.is-ancestor:not(:last-child) {
	margin-bottom: .75rem
}

.tile.is-child {
	margin: 0!important
}

.tile.is-parent {
	padding: .75rem
}

.tile.is-vertical {
	flex-direction: column
}

.tile.is-vertical>.tile.is-child:not(:last-child) {
	margin-bottom: 1.5rem!important
}

@media screen and (min-width: 1024px),
print {
	.tile:not(.is-child) {
		display: flex
	}
	.tile.is-1 {
		flex: none;
		width: 8.33333%
	}
	.tile.is-2 {
		flex: none;
		width: 16.66667%
	}
	.tile.is-3 {
		flex: none;
		width: 25%
	}
	.tile.is-4 {
		flex: none;
		width: 33.33333%
	}
	.tile.is-5 {
		flex: none;
		width: 41.66667%
	}
	.tile.is-6 {
		flex: none;
		width: 50%
	}
	.tile.is-7 {
		flex: none;
		width: 58.33333%
	}
	.tile.is-8 {
		flex: none;
		width: 66.66667%
	}
	.tile.is-9 {
		flex: none;
		width: 75%
	}
	.tile.is-10 {
		flex: none;
		width: 83.33333%
	}
	.tile.is-11 {
		flex: none;
		width: 91.66667%
	}
	.tile.is-12 {
		flex: none;
		width: 100%
	}
}

.has-text-white {
	color: #fff!important
}

a.has-text-white:hover,
a.has-text-white:focus {
	color: #e6e6e6!important
}

.has-background-white {
	background-color: #fff!important
}

.has-text-black {
	color: #0a0a0a!important
}

a.has-text-black:hover,
a.has-text-black:focus {
	color: #000!important
}

.has-background-black {
	background-color: #0a0a0a!important
}

.has-text-light {
	color: #f5f5f5!important
}

a.has-text-light:hover,
a.has-text-light:focus {
	color: #dbdbdb!important
}

.has-background-light {
	background-color: #f5f5f5!important
}

.has-text-dark {
	color: #363636!important
}

a.has-text-dark:hover,
a.has-text-dark:focus {
	color: #1c1c1c!important
}

.has-background-dark {
	background-color: #363636!important
}

.has-text-primary {
	color: #00d1b2!important
}

a.has-text-primary:hover,
a.has-text-primary:focus {
	color: #009e86!important
}

.has-background-primary {
	background-color: #00d1b2!important
}

.has-text-primary-light {
	color: #ebfffc!important
}

a.has-text-primary-light:hover,
a.has-text-primary-light:focus {
	color: #b8fff4!important
}

.has-background-primary-light {
	background-color: #ebfffc!important
}

.has-text-primary-dark {
	color: #00947e!important
}

a.has-text-primary-dark:hover,
a.has-text-primary-dark:focus {
	color: #00c7a9!important
}

.has-background-primary-dark {
	background-color: #00947e!important
}

.has-text-link {
	color: #3273dc!important
}

a.has-text-link:hover,
a.has-text-link:focus {
	color: #205bbc!important
}

.has-background-link {
	background-color: #3273dc!important
}

.has-text-link-light {
	color: #eef3fc!important
}

a.has-text-link-light:hover,
a.has-text-link-light:focus {
	color: #c2d5f5!important
}

.has-background-link-light {
	background-color: #eef3fc!important
}

.has-text-link-dark {
	color: #2160c4!important
}

a.has-text-link-dark:hover,
a.has-text-link-dark:focus {
	color: #3b79de!important
}

.has-background-link-dark {
	background-color: #2160c4!important
}

.has-text-info {
	color: #3298dc!important
}

a.has-text-info:hover,
a.has-text-info:focus {
	color: #207dbc!important
}

.has-background-info {
	background-color: #3298dc!important
}

.has-text-info-light {
	color: #eef6fc!important
}

a.has-text-info-light:hover,
a.has-text-info-light:focus {
	color: #c2e0f5!important
}

.has-background-info-light {
	background-color: #eef6fc!important
}

.has-text-info-dark {
	color: #1d72aa!important
}

a.has-text-info-dark:hover,
a.has-text-info-dark:focus {
	color: #248fd6!important
}

.has-background-info-dark {
	background-color: #1d72aa!important
}

.has-text-success {
	color: #48c774!important
}

a.has-text-success:hover,
a.has-text-success:focus {
	color: #34a85c!important
}

.has-background-success {
	background-color: #48c774!important
}

.has-text-success-light {
	color: #effaf3!important
}

a.has-text-success-light:hover,
a.has-text-success-light:focus {
	color: #c8eed6!important
}

.has-background-success-light {
	background-color: #effaf3!important
}

.has-text-success-dark {
	color: #257942!important
}

a.has-text-success-dark:hover,
a.has-text-success-dark:focus {
	color: #31a058!important
}

.has-background-success-dark {
	background-color: #257942!important
}

.has-text-warning {
	color: #ffdd57!important
}

a.has-text-warning:hover,
a.has-text-warning:focus {
	color: #ffd324!important
}

.has-background-warning {
	background-color: #ffdd57!important
}

.has-text-warning-light {
	color: #fffbeb!important
}

a.has-text-warning-light:hover,
a.has-text-warning-light:focus {
	color: #fff1b8!important
}

.has-background-warning-light {
	background-color: #fffbeb!important
}

.has-text-warning-dark {
	color: #947600!important
}

a.has-text-warning-dark:hover,
a.has-text-warning-dark:focus {
	color: #c79f00!important
}

.has-background-warning-dark {
	background-color: #947600!important
}




.has-text-danger {
	color: #f14668!important
}

a.has-text-danger:hover,
a.has-text-danger:focus {
	color: #ee1742!important
}

.has-background-danger {
	background-color: #f14668!important
}

.has-text-danger-light {
	color: #feecf0!important
}

a.has-text-danger-light:hover,
a.has-text-danger-light:focus {
	color: #fabdc9!important
}

.has-background-danger-light {
	background-color: #feecf0!important
}

.has-text-danger-dark {
	color: #cc0f35!important
}

a.has-text-danger-dark:hover,
a.has-text-danger-dark:focus {
	color: #ee2049!important
}

.has-background-danger-dark {
	background-color: #cc0f35!important
}

.has-text-black-bis {
	color: #121212!important
}

.has-background-black-bis {
	background-color: #121212!important
}

.has-text-black-ter {
	color: #242424!important
}

.has-background-black-ter {
	background-color: #242424!important
}

.has-text-grey-darker {
	color: #363636!important
}

.has-background-grey-darker {
	background-color: #363636!important
}

.has-text-grey-dark {
	color: #4a4a4a!important
}

.has-background-grey-dark {
	background-color: #4a4a4a!important
}

.has-text-grey {
	color: #7a7a7a!important
}

.has-background-grey {
	background-color: #7a7a7a!important
}

.has-text-grey-light {
	color: #b5b5b5!important
}

.has-background-grey-light {
	background-color: #b5b5b5!important
}

.has-text-grey-lighter {
	color: #dbdbdb!important
}

.has-background-grey-lighter {
	background-color: #dbdbdb!important
}

.has-text-white-ter {
	color: #f5f5f5!important
}

.has-background-white-ter {
	background-color: #f5f5f5!important
}

.has-text-white-bis {
	color: #fafafa!important
}

.has-background-white-bis {
	background-color: #fafafa!important
}

.is-flex-direction-row {
	flex-direction: row!important
}

.is-flex-direction-row-reverse {
	flex-direction: row-reverse!important
}

.is-flex-direction-column {
	flex-direction: column!important
}

.is-flex-direction-column-reverse {
	flex-direction: column-reverse!important
}

.is-flex-wrap-nowrap {
	flex-wrap: nowrap!important
}

.is-flex-wrap-wrap {
	flex-wrap: wrap!important
}

.is-flex-wrap-wrap-reverse {
	flex-wrap: wrap-reverse!important
}

.is-justify-content-flex-start {
	justify-content: flex-start!important
}

.is-justify-content-flex-end {
	justify-content: flex-end!important
}

.is-justify-content-center {
	justify-content: center!important
}

.is-justify-content-space-between {
	justify-content: space-between!important
}

.is-justify-content-space-around {
	justify-content: space-around!important
}

.is-justify-content-space-evenly {
	justify-content: space-evenly!important
}

.is-justify-content-start {
	justify-content: start!important
}

.is-justify-content-end {
	justify-content: end!important
}

.is-justify-content-left {
	justify-content: left!important
}

.is-justify-content-right {
	justify-content: right!important
}

.is-align-content-flex-start {
	align-content: flex-start!important
}

.is-align-content-flex-end {
	align-content: flex-end!important
}

.is-align-content-center {
	align-content: center!important
}

.is-align-content-space-between {
	align-content: space-between!important
}

.is-align-content-space-around {
	align-content: space-around!important
}

.is-align-content-space-evenly {
	align-content: space-evenly!important
}

.is-align-content-stretch {
	align-content: stretch!important
}

.is-align-content-start {
	align-content: start!important
}

.is-align-content-end {
	align-content: end!important
}

.is-align-content-baseline {
	align-content: baseline!important
}

.is-align-items-stretch {
	align-items: stretch!important
}

.is-align-items-flex-start {
	align-items: flex-start!important
}

.is-align-items-flex-end {
	align-items: flex-end!important
}

.is-align-items-center {
	align-items: center!important
}

.is-align-items-baseline {
	align-items: baseline!important
}

.is-align-items-start {
	align-items: start!important
}

.is-align-items-end {
	align-items: end!important
}

.is-align-items-self-start {
	align-items: self-start!important
}

.is-align-items-self-end {
	align-items: self-end!important
}

.is-align-self-auto {
	align-self: auto!important
}

.is-align-self-flex-start {
	align-self: flex-start!important
}

.is-align-self-flex-end {
	align-self: flex-end!important
}

.is-align-self-center {
	align-self: center!important
}

.is-align-self-baseline {
	align-self: baseline!important
}

.is-align-self-stretch {
	align-self: stretch!important
}

.is-flex-grow-0 {
	flex-grow: 0!important
}

.is-flex-grow-1 {
	flex-grow: 1!important
}

.is-flex-grow-2 {
	flex-grow: 2!important
}

.is-flex-grow-3 {
	flex-grow: 3!important
}

.is-flex-grow-4 {
	flex-grow: 4!important
}

.is-flex-grow-5 {
	flex-grow: 5!important
}

.is-flex-shrink-0 {
	flex-shrink: 0!important
}

.is-flex-shrink-1 {
	flex-shrink: 1!important
}

.is-flex-shrink-2 {
	flex-shrink: 2!important
}

.is-flex-shrink-3 {
	flex-shrink: 3!important
}

.is-flex-shrink-4 {
	flex-shrink: 4!important
}

.is-flex-shrink-5 {
	flex-shrink: 5!important
}

.is-clearfix::after {
	clear: both;
	content: " ";
	display: table
}

.is-pulled-left {
	float: left!important
}

.is-pulled-right {
	float: right!important
}

.is-radiusless {
	border-radius: 0!important
}

.is-shadowless {
	box-shadow: none!important
}

.is-clickable {
	cursor: pointer!important
}

.is-clipped {
	overflow: hidden!important
}

.is-relative {
	position: relative!important
}

.is-marginless {
	margin: 0!important
}

.is-paddingless {
	padding: 0!important
}

.is-size-1 {
	font-size: 3rem!important
}

.is-size-2 {
	font-size: 2.5rem!important
}

.is-size-3 {
	font-size: 2rem!important
}

.is-size-4 {
	font-size: 1.5rem!important
}

.is-size-5 {
	font-size: 1.25rem!important
}

.is-size-6 {
	font-size: 1rem!important
}

.is-size-7 {
	font-size: .75rem!important
}

@media screen and (max-width:1024px) {
	.is-size-1-mobile {
		font-size: 3rem!important
	}
	.is-size-2-mobile {
		font-size: 2.5rem!important
	}
	.is-size-3-mobile {
		font-size: 2rem!important
	}
	.is-size-4-mobile {
		font-size: 1.5rem!important
	}
	.is-size-5-mobile {
		font-size: 1.25rem!important
	}
	.is-size-6-mobile {
		font-size: 1rem!important
	}
	.is-size-7-mobile {
		font-size: .75rem!important
	}
}



@media screen and (min-width: 1024px) {
	.is-size-1-desktop {
		font-size: 3rem!important
	}
	.is-size-2-desktop {
		font-size: 2.5rem!important
	}
	.is-size-3-desktop {
		font-size: 2rem!important
	}
	.is-size-4-desktop {
		font-size: 1.5rem!important
	}
	.is-size-5-desktop {
		font-size: 1.25rem!important
	}
	.is-size-6-desktop {
		font-size: 1rem!important
	}
	.is-size-7-desktop {
		font-size: .75rem!important
	}
}


.has-text-centered {
	text-align: center!important
}

.has-text-justified {
	text-align: justify!important
}

.has-text-left {
	text-align: left!important
}

.has-text-right {
	text-align: right!important
}

@media screen and (max-width: 768px) {
	.has-text-centered-mobile {
		text-align: center!important
	}
}


@media screen and (min-width: 1024px) {
	.has-text-centered-desktop {
		text-align: center!important
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.has-text-centered-desktop-only {
		text-align: center!important
	}
}

@media screen and (max-width: 768px) {
	.has-text-justified-mobile {
		text-align: justify!important
	}
}


@media screen and (min-width: 1024px) {
	.has-text-justified-desktop {
		text-align: justify!important
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.has-text-justified-desktop-only {
		text-align: justify!important
	}
}

@media screen and (max-width: 768px) {
	.has-text-left-mobile {
		text-align: left!important
	}
}


@media screen and (min-width: 1024px) {
	.has-text-left-desktop {
		text-align: left!important
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.has-text-left-desktop-only {
		text-align: left!important
	}
}


@media screen and (max-width: 768px) {
	.has-text-right-mobile {
		text-align: right!important
	}
}

@media screen and (min-width: 1024px) {
	.has-text-right-desktop {
		text-align: right!important
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.has-text-right-desktop-only {
		text-align: right!important
	}
}


.is-capitalized {
	text-transform: capitalize!important
}

.is-lowercase {
	text-transform: lowercase!important
}

.is-uppercase {
	text-transform: uppercase!important
}

.is-italic {
	font-style: italic!important
}

.has-text-weight-light {
	font-weight: 300!important
}

.has-text-weight-normal {
	font-weight: 400!important
}

.has-text-weight-medium {
	font-weight: 500!important
}

.has-text-weight-semibold {
	font-weight: 600!important
}

.has-text-weight-bold {
	font-weight: 700!important
}

.is-flex {
	display: flex!important
}

.is-inline {
	display: inline!important
}

.is-inline-block {
	display: inline-block!important
}

.is-inline-flex {
	display: inline-flex!important
}

.is-hidden {
	display: none!important
}

@media screen and (max-width: 1024px) {
	.is-hidden-mobile {
		display: none!important
	}
}


@media screen and (min-width: 1024px) {
	.is-hidden-desktop {
		display: none!important
	}
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.is-hidden-desktop-only {
		display: none!important
	}
}

.is-invisible {
	visibility: hidden!important
}

@media screen and (max-width: 768px) {
	.is-invisible-mobile {
		visibility: hidden!important
	}
}

.hero {
	align-items: stretch;
	display: flex;
	flex-direction: column;
	justify-content: space-between
}

.hero .navbar {
	background: none
}

.hero .tabs ul {
	border-bottom: none
}

.hero.is-light {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-light strong {
	color: inherit
}

.hero.is-light .title {
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-light .subtitle {
	color: rgba(0, 0, 0, 0.9)
}

.hero.is-light .subtitle a:not(.button),
.hero.is-light .subtitle strong {
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-light .navbar-item,
.hero.is-light .navbar-link {
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-light a.navbar-item:hover,
.hero.is-light a.navbar-item.is-active,
.hero.is-light .navbar-link:hover,
.hero.is-light .navbar-link.is-active {
	background-color: #e8e8e8;
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-light .tabs a {
	color: rgba(0, 0, 0, 0.7);
	opacity: .9
}

.hero.is-light .tabs a:hover {
	opacity: 1
}

.hero.is-light .tabs li.is-active a {
	opacity: 1
}

.hero.is-light .tabs.is-boxed a,
.hero.is-light .tabs.is-toggle a {
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-light .tabs.is-boxed a:hover,
.hero.is-light .tabs.is-toggle a:hover {
	background-color: rgba(10, 10, 10, 0.1)
}

.hero.is-light .tabs.is-boxed li.is-active a,
.hero.is-light .tabs.is-boxed li.is-active a:hover,
.hero.is-light .tabs.is-toggle li.is-active a,
.hero.is-light .tabs.is-toggle li.is-active a:hover {
	background-color: rgba(0, 0, 0, 0.7);
	border-color: rgba(0, 0, 0, 0.7);
	color: #f5f5f5
}

.hero.is-light.is-bold {
	background-image: linear-gradient(141deg, #dfd8d9 0%, whitesmoke 71%, white 100%)
}


.hero.is-dark {
	background-color: #363636;
	color: #fff
}

.hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-dark strong {
	color: inherit
}

.hero.is-dark .title {
	color: #fff
}

.hero.is-dark .subtitle {
	color: rgba(255, 255, 255, 0.9)
}

.hero.is-dark .subtitle a:not(.button),
.hero.is-dark .subtitle strong {
	color: #fff
}


.hero.is-dark .navbar-item,
.hero.is-dark .navbar-link {
	color: rgba(255, 255, 255, 0.7)
}

.hero.is-dark a.navbar-item:hover,
.hero.is-dark a.navbar-item.is-active,
.hero.is-dark .navbar-link:hover,
.hero.is-dark .navbar-link.is-active {
	background-color: #292929;
	color: #fff
}

.hero.is-dark .tabs a {
	color: #fff;
	opacity: .9
}

.hero.is-dark .tabs a:hover {
	opacity: 1
}

.hero.is-dark .tabs li.is-active a {
	opacity: 1
}

.hero.is-dark .tabs.is-boxed a,
.hero.is-dark .tabs.is-toggle a {
	color: #fff
}

.hero.is-dark .tabs.is-boxed a:hover,
.hero.is-dark .tabs.is-toggle a:hover {
	background-color: rgba(10, 10, 10, 0.1)
}

.hero.is-dark .tabs.is-boxed li.is-active a,
.hero.is-dark .tabs.is-boxed li.is-active a:hover,
.hero.is-dark .tabs.is-toggle li.is-active a,
.hero.is-dark .tabs.is-toggle li.is-active a:hover {
	background-color: #fff;
	border-color: #fff;
	color: #363636
}

.hero.is-dark.is-bold {
	background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%)
}

.hero.is-primary {
	background-color: #00d1b2;
	color: #fff
}

.hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-primary strong {
	color: inherit
}

.hero.is-primary .title {
	color: #fff
}

.hero.is-primary .subtitle {
	color: rgba(255, 255, 255, 0.9)
}

.hero.is-primary .subtitle a:not(.button),
.hero.is-primary .subtitle strong {
	color: #fff
}

.hero.is-primary .navbar-item,
.hero.is-primary .navbar-link {
	color: rgba(255, 255, 255, 0.7)
}

.hero.is-primary a.navbar-item:hover,
.hero.is-primary a.navbar-item.is-active,
.hero.is-primary .navbar-link:hover,
.hero.is-primary .navbar-link.is-active {
	background-color: #00b89c;
	color: #fff
}

.hero.is-primary .tabs a {
	color: #fff;
	opacity: .9
}

.hero.is-primary .tabs a:hover {
	opacity: 1
}

.hero.is-primary .tabs li.is-active a {
	opacity: 1
}

.hero.is-primary .tabs.is-boxed a,
.hero.is-primary .tabs.is-toggle a {
	color: #fff
}

.hero.is-primary .tabs.is-boxed a:hover,
.hero.is-primary .tabs.is-toggle a:hover {
	background-color: rgba(10, 10, 10, 0.1)
}

.hero.is-primary .tabs.is-boxed li.is-active a,
.hero.is-primary .tabs.is-boxed li.is-active a:hover,
.hero.is-primary .tabs.is-toggle li.is-active a,
.hero.is-primary .tabs.is-toggle li.is-active a:hover {
	background-color: #fff;
	border-color: #fff;
	color: #00d1b2
}

.hero.is-primary.is-bold {
	background-image: linear-gradient(141deg, #009e6c 0%, #00d1b2 71%, #00e7eb 100%)
}


.hero.is-info {
	background-color: #3298dc;
	color: #fff
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-info strong {
	color: inherit
}

.hero.is-info .title {
	color: #fff
}

.hero.is-info .subtitle {
	color: rgba(255, 255, 255, 0.9)
}

.hero.is-info .subtitle a:not(.button),
.hero.is-info .subtitle strong {
	color: #fff
}

.hero.is-info .navbar-item,
.hero.is-info .navbar-link {
	color: rgba(255, 255, 255, 0.7)
}

.hero.is-info a.navbar-item:hover,
.hero.is-info a.navbar-item.is-active,
.hero.is-info .navbar-link:hover,
.hero.is-info .navbar-link.is-active {
	background-color: #238cd1;
	color: #fff
}

.hero.is-info .tabs a {
	color: #fff;
	opacity: .9
}

.hero.is-info .tabs a:hover {
	opacity: 1
}

.hero.is-info .tabs li.is-active a {
	opacity: 1
}

.hero.is-info .tabs.is-boxed a,
.hero.is-info .tabs.is-toggle a {
	color: #fff
}

.hero.is-info .tabs.is-boxed a:hover,
.hero.is-info .tabs.is-toggle a:hover {
	background-color: rgba(10, 10, 10, 0.1)
}

.hero.is-info .tabs.is-boxed li.is-active a,
.hero.is-info .tabs.is-boxed li.is-active a:hover,
.hero.is-info .tabs.is-toggle li.is-active a,
.hero.is-info .tabs.is-toggle li.is-active a:hover {
	background-color: #fff;
	border-color: #fff;
	color: #3298dc
}

.hero.is-info.is-bold {
	background-image: linear-gradient(141deg, #159dc6 0%, #3298dc 71%, #4389e5 100%)
}

.hero.is-success {
	background-color: #48c774;
	color: #fff
}

.hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-success strong {
	color: inherit
}

.hero.is-success .title {
	color: #fff
}

.hero.is-success .subtitle {
	color: rgba(255, 255, 255, 0.9)
}

.hero.is-success .subtitle a:not(.button),
.hero.is-success .subtitle strong {
	color: #fff
}

.hero.is-success .navbar-item,
.hero.is-success .navbar-link {
	color: rgba(255, 255, 255, 0.7)
}

.hero.is-success a.navbar-item:hover,
.hero.is-success a.navbar-item.is-active,
.hero.is-success .navbar-link:hover,
.hero.is-success .navbar-link.is-active {
	background-color: #3abb67;
	color: #fff
}

.hero.is-success .tabs a {
	color: #fff;
	opacity: .9
}

.hero.is-success .tabs a:hover {
	opacity: 1
}

.hero.is-success .tabs li.is-active a {
	opacity: 1
}

.hero.is-success .tabs.is-boxed a,
.hero.is-success .tabs.is-toggle a {
	color: #fff
}

.hero.is-success .tabs.is-boxed a:hover,
.hero.is-success .tabs.is-toggle a:hover {
	background-color: rgba(10, 10, 10, 0.1)
}

.hero.is-success .tabs.is-boxed li.is-active a,
.hero.is-success .tabs.is-boxed li.is-active a:hover,
.hero.is-success .tabs.is-toggle li.is-active a,
.hero.is-success .tabs.is-toggle li.is-active a:hover {
	background-color: #fff;
	border-color: #fff;
	color: #48c774
}

.hero.is-success.is-bold {
	background-image: linear-gradient(141deg, #29b342 0%, #48c774 71%, #56d296 100%)
}

.hero.is-warning {
	background-color: #ffdd57;
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-warning strong {
	color: inherit
}

.hero.is-warning .title {
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-warning .subtitle {
	color: rgba(0, 0, 0, 0.9)
}

.hero.is-warning .subtitle a:not(.button),
.hero.is-warning .subtitle strong {
	color: rgba(0, 0, 0, 0.7)
}


.hero.is-warning .navbar-item,
.hero.is-warning .navbar-link {
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-warning a.navbar-item:hover,
.hero.is-warning a.navbar-item.is-active,
.hero.is-warning .navbar-link:hover,
.hero.is-warning .navbar-link.is-active {
	background-color: #ffd83d;
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-warning .tabs a {
	color: rgba(0, 0, 0, 0.7);
	opacity: .9
}

.hero.is-warning .tabs a:hover {
	opacity: 1
}

.hero.is-warning .tabs li.is-active a {
	opacity: 1
}

.hero.is-warning .tabs.is-boxed a,
.hero.is-warning .tabs.is-toggle a {
	color: rgba(0, 0, 0, 0.7)
}

.hero.is-warning .tabs.is-boxed a:hover,
.hero.is-warning .tabs.is-toggle a:hover {
	background-color: rgba(10, 10, 10, 0.1)
}

.hero.is-warning .tabs.is-boxed li.is-active a,
.hero.is-warning .tabs.is-boxed li.is-active a:hover,
.hero.is-warning .tabs.is-toggle li.is-active a,
.hero.is-warning .tabs.is-toggle li.is-active a:hover {
	background-color: rgba(0, 0, 0, 0.7);
	border-color: rgba(0, 0, 0, 0.7);
	color: #ffdd57
}

.hero.is-warning.is-bold {
	background-image: linear-gradient(141deg, #ffaf24 0%, #ffdd57 71%, #fffa70 100%)
}


.hero.is-danger {
	background-color: #f14668;
	color: #fff
}

.hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current),
.hero.is-danger strong {
	color: inherit
}

.hero.is-danger .title {
	color: #fff
}

.hero.is-danger .subtitle {
	color: rgba(255, 255, 255, 0.9)
}

.hero.is-danger .subtitle a:not(.button),
.hero.is-danger .subtitle strong {
	color: #fff
}


.hero.is-danger .navbar-item,
.hero.is-danger .navbar-link {
	color: rgba(255, 255, 255, 0.7)
}

.hero.is-danger a.navbar-item:hover,
.hero.is-danger a.navbar-item.is-active,
.hero.is-danger .navbar-link:hover,
.hero.is-danger .navbar-link.is-active {
	background-color: #ef2e55;
	color: #fff
}

.hero.is-danger .tabs a {
	color: #fff;
	opacity: .9
}

.hero.is-danger .tabs a:hover {
	opacity: 1
}

.hero.is-danger .tabs li.is-active a {
	opacity: 1
}

.hero.is-danger .tabs.is-boxed a,
.hero.is-danger .tabs.is-toggle a {
	color: #fff
}

.hero.is-danger .tabs.is-boxed a:hover,
.hero.is-danger .tabs.is-toggle a:hover {
	background-color: rgba(10, 10, 10, 0.1)
}

.hero.is-danger .tabs.is-boxed li.is-active a,
.hero.is-danger .tabs.is-boxed li.is-active a:hover,
.hero.is-danger .tabs.is-toggle li.is-active a,
.hero.is-danger .tabs.is-toggle li.is-active a:hover {
	background-color: #fff;
	border-color: #fff;
	color: #f14668
}

.hero.is-danger.is-bold {
	background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%)
}

.hero.is-small .hero-body {
	padding: 1.5rem
}

@media screen and (min-width: 1024px),
print {
	.hero.is-medium .hero-body {
		padding: 9rem 1.5rem
	}
}

@media screen and (min-width: 1024px),
print {
	.hero.is-large .hero-body {
		padding: 18rem 1.5rem
	}
}

.hero.is-halfheight .hero-body,
.hero.is-fullheight .hero-body,
.hero.is-fullheight-with-navbar .hero-body {
	align-items: center;
	display: flex
}

.hero.is-halfheight .hero-body>.container,
.hero.is-fullheight .hero-body>.container,
.hero.is-fullheight-with-navbar .hero-body>.container {
	flex-grow: 1;
	flex-shrink: 1
}

.hero.is-halfheight {
	min-height: 50vh
}

.hero.is-fullheight {
	min-height: 100vh
}



.section {
	padding: 3rem 1.5rem
}

@media screen and (min-width: 1024px) {
	.section.is-medium {
		padding: 9rem 1.5rem
	}
	.section.is-large {
		padding: 18rem 1.5rem
	}
}

.footer {
	background-color: #fafafa;
	padding: 3rem 1.5rem 6rem
}